import type React from 'react'
import { useTranslation } from 'next-i18next'

import type { QaHook, Styles } from '@knauf-group/ct-designs/utils/types'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'

import { showCookieInfoPopup } from '../OneTrustCookieManager'

type VideoCookieWarningProps = QaHook

const VideoCookieWarning: React.FC<VideoCookieWarningProps> = ({
  dataCy = 'cookie-warning',
}) => {
  const theme = useTheme()
  const { t } = useTranslation('cms', { keyPrefix: 'common.cookies' })

  const styles: Styles = {
    root: {
      width: '100%',
      textAlign: 'left',

      '& .MuiAlert-action': {
        width: { xs: '100%', sm: 'initial' },
        color: 'inherit',
        alignItems: { xs: 'start', sm: 'center' },
        mt: {
          xs: '5px',
          sm: '0',
        },
        paddingLeft: {
          xs: theme.spacing(3),
          sm: 'inherit',
        },
      },
      '&.MuiPaper-root': {
        xs: {
          display: 'block',
        },
        sm: {
          display: 'flex',
        },
      },
      '& .MuiAlert-icon': {
        xs: {
          float: 'left',
        },
        sm: {
          float: 'none',
        },
      },
    },
  }

  return (
    <Alert
      sx={styles.root}
      severity="warning"
      data-cy={dataCy}
      action={
        <Button onClick={showCookieInfoPopup} variant="text" color="inherit" size="small">
          {t('cookieWarningLink')}
        </Button>
      }
    >
      {t('cookieWarningMessage')}
    </Alert>
  )
}

export default VideoCookieWarning
